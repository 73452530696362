//TODO: Better handle state, preserve across page transitions
/*@ngInject*/
function ResultsCtrl(
  $scope,
  $timeout,
  $routeParams,
  $log,
  $location,
  $q,
  $interval,
  jobTracker,
  jobEvents,
  userProfile,
  userTokens,
  Jobs
) {
  this.searchQuery = null;
  this.searchSort = null;
  this.searchFrom = null;
  this.searchSize = null;
  this.err = null;
  this.searchMode = false;
  this.searchFields = [];

  // defaults
  this.type = "completed";
  this.failBanner = "No completed jobs found";
  this.transformedSearchQuery = null;

  this.profile = userProfile;
  this.jobResultsFetched = false;

  this.updateError = null;
  this.fetchingResults = false;

  this.jobBeingViewed = {};

  this.hasJob = () => Object.keys(this.jobBeingViewed || {}).length;

  // only exists for completed jobs
  this.jobStats = null;

  this.clearSearch = () => {
    this.searchMode = false;
    this.searchQuery = null;
    this.searchSort = null;
    this.searchFrom = null;
    this.searchSize = null;
  };

  this.fieldAutocompleteSuggestions = [];

  this.highlightedIndex = -1; // No suggestion highlighted initially

  this.cursorPosition = 0;

  this.maxSuggestions = 20;

  let updateInterval;

  let scrollTimeout = null;
  this.handleSearchFieldChange = ($event) => {
    switch ($event.keyCode) {
      case 38: // Arrow up
        $event.preventDefault();
        if (this.highlightedIndex > 0) {
          $event.preventDefault();
          this.highlightedIndex--;

          if (scrollTimeout) {
            clearTimeout(scrollTimeout);
          }

          scrollTimeout = setTimeout((highlightedIndex) => {
            const suggestionListElement = document.getElementById('autocomplete-suggestions-list');
            const highlightedElement = suggestionListElement.children[highlightedIndex];

            $scope.$evalAsync(() => {
              highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            });
          }, 50, this.highlightedIndex);
        }
        break;
      case 40: // Arrow down
        $event.preventDefault();
        if (this.highlightedIndex < this.fieldAutocompleteSuggestions.length - 1) {

          this.highlightedIndex++;

          if (scrollTimeout) {
            clearTimeout(scrollTimeout);
          }

          scrollTimeout = setTimeout((highlightedIndex) => {
            // Scroll logic
            const suggestionListElement = document.getElementById('autocomplete-suggestions-list');
            const highlightedElement = suggestionListElement.children[highlightedIndex];

            if (highlightedElement) {
              $scope.$evalAsync(() => {
                highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
              });
            }
          }, 50, this.highlightedIndex);
        }
        break;
      case 9: // tab
        $event.preventDefault();
        if (this.fieldAutocompleteSuggestions.length > 0) {
          if (this.highlightedIndex == -1) {
            this.selectSuggestion(this.fieldAutocompleteSuggestions[0]);
          } else {
            this.selectSuggestion(this.fieldAutocompleteSuggestions[this.highlightedIndex]);
          }
        }
        this.submitQueryChange(this.viewQuery, false);

        this.highlightedIndex = -1; // Reset highlighted index
        this.fieldAutocompleteSuggestions = [];
        break;
      case 13: // Enter key
        $event.preventDefault();
        if (this.fieldAutocompleteSuggestions.length > 0) {
          if (this.highlightedIndex == -1) {
            this.selectSuggestion(this.fieldAutocompleteSuggestions[0]);
          } else {
            this.selectSuggestion(this.fieldAutocompleteSuggestions[this.highlightedIndex]);
          }
        }
        this.submitQueryChange(this.viewQuery, true);

        this.highlightedIndex = -1; // Reset highlighted index
        this.fieldAutocompleteSuggestions = [];
        break;
      case 229:
        // Firefox fires this off for every key press, so we need to ignore it
        break;
      default:
        this.updateSuggestions($event);
    }
  };

  function getSuggestionsWithPadding(inputQuery, cursorPosition, suggestion, prefix) {
    const queryPart = inputQuery.substring(0, cursorPosition - prefix.length);
    return `<span class='hidden-text'>${queryPart}</span>` + suggestion;
  }

  let updateSuggestionsTimeout = null;
  this.updateSuggestions = ($event) => {
    if (!this.searchFields.length) {
      $log.warn('Search fields not yet fetched but suggestions requested.');
      return;
    }

    if (updateSuggestionsTimeout) {
      $timeout.cancel(updateSuggestionsTimeout);
    }

    updateSuggestionsTimeout = $timeout(() => {
      let input = this.viewQuery;
      // You need a way to get the current cursor position within the input field.
      // This example assumes you have access to the input element somehow to get its selectionStart.
      // For AngularJS, you might need to directly access the element via a ref or similar method.
      const cursorPosition = $event.target.selectionStart;
      this.cursorPosition = cursorPosition;
      let lastChar = cursorPosition > 0 ? input[cursorPosition - 1] : null;

      if (!lastChar || lastChar === " " || lastChar === ':' || lastChar === '(' || lastChar === ")" || lastChar === '[' || lastChar === ']' || lastChar === '+' || lastChar === '-' || lastChar === '"') {
        $scope.$evalAsync(() => {
          this.fieldAutocompleteSuggestions = [];
          this.highlightedIndex = -1;
        });
        return;
      }

      let lastSegment = input.substring(0, cursorPosition).split(/\s+/).pop(); // Adjusted to consider cursor position
      let strippedSegment = lastSegment.replace(/^[\+\-\("]+/, ''); // Strip starting special characters

      // Test if there is a preceding colon with no spaces, ), ], +, -, or " before it
      if (/[^)\]\}\s\+\-"]*:$/.test(input.substring(0, cursorPosition))) {
        $scope.$evalAsync(() => {
          this.fieldAutocompleteSuggestions = [];
          this.highlightedIndex = -1;
        });
        return;
      }

      let prefix = strippedSegment && strippedSegment.trim();

      if (prefix) {
        const exactMatch = this.searchFields.find(item => item.toLowerCase() === prefix.toLowerCase());
        const otherMatches = this.searchFields.filter(item =>
          item.toLowerCase() !== prefix.toLowerCase() && item.toLowerCase().startsWith(prefix.toLowerCase())
        );
        const matches = exactMatch ? [exactMatch].concat(otherMatches) : otherMatches;

        $scope.$evalAsync(() => {
          this.fieldAutocompleteSuggestions = matches.map(suggestion => {
            let displayValue = suggestion;
            if (suggestion.toLowerCase().startsWith(prefix.toLowerCase())) {
              const repValue = `<b>${suggestion.substring(0, prefix.length)}</b>`;

              displayValue = repValue + suggestion.substring(prefix.length);
            }

            displayValue = getSuggestionsWithPadding(input, cursorPosition, displayValue, prefix);

            return {
              display: displayValue,
              value: suggestion
            };
          });

          if (this.fieldAutocompleteSuggestions.length) {
            if (scrollTimeout) {
              clearTimeout(scrollTimeout);
            }

            this.highlightedIndex = -1;
            scrollTimeout = setTimeout(() => {
              const suggestionListElement = document.getElementById('autocomplete-suggestions-list');
              const highlightedElement = suggestionListElement.children[0];

              $scope.$evalAsync(() => {
                highlightedElement.scrollIntoView({ behavior: 'auto', block: 'nearest' });
              });
            }, 0);
          }

        });
      } else {
        $scope.$evalAsync(() => {
          this.fieldAutocompleteSuggestions = [];
        });
      }
    }, 16);
  };

  this.selectSuggestion = (suggestionObj) => {
    const suggestion = suggestionObj['value'];
    const cursorPosition = this.cursorPosition;
    let inputUntilCursor = this.viewQuery.substring(0, cursorPosition);
    // Find the start position for the last word segment, stripping leading special characters for replacement
    const startIndex = inputUntilCursor.search(/[\w\.]+$/);
    const endIndex = inputUntilCursor.length;

    // query after the cursor
    const queryAfterCursor = this.viewQuery.substring(cursorPosition);

    let partialQuery = this.viewQuery + suggestion + ':';
    if (startIndex !== -1) {
      partialQuery = inputUntilCursor.substring(0, startIndex) + suggestion + inputUntilCursor.substring(endIndex) + ":";
      // Replace the last segment with the selected suggestion, preserving special characters at the start
      this.viewQuery = partialQuery + queryAfterCursor;
    } else {
      // If no valid segment is found, append the suggestion normally
      this.viewQuery += suggestion + ':';
    }

    const inputElement = document.getElementById('search-input');
    if (inputElement) {
      const newCursorPosition = partialQuery.length;
      inputElement.value = this.viewQuery; // Update the value in the input element
      inputElement.focus(); // Focus the input element
      inputElement.setSelectionRange(newCursorPosition, newCursorPosition); // Set cursor position
    }
    this.fieldAutocompleteSuggestions = [];
    this.highlightedIndex = -1;
  };

  this.setupClickListener = () => {
    document.addEventListener('click', this.handleClickOutside, true);
  };

  this.removeClickListener = () => {
    document.removeEventListener('click', this.handleClickOutside, true);
  };

  this.handleClickOutside = (event) => {
    // Assuming your suggestion list has a specific class or ID
    const suggestionsElement = document.getElementById('autocomplete-suggestions');
    if (suggestionsElement && !suggestionsElement.contains(event.target)) {
      $scope.$evalAsync(() => {
        this.fieldAutocompleteSuggestions = [];
        this.highlightedIndex = -1;
      });
    }
  };

  const pendingRequests = new Map();
  this.getCompletedJobStats = (job) => {
    if (pendingRequests.has(job._id)) {
      pendingRequests.get(job._id).abort();
    }

    this.jobStats = null;
    const controller = new AbortController();
    pendingRequests.set(job._id, controller);
    return fetch('/api/jobs/' + job._id + '/stats', {
      headers: {
        'Authorization': 'Bearer ' + userTokens.accessToken
      },
      signal: controller.signal
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    }).then(data => {
      $scope.$evalAsync(() => {
        this.jobStats = Object.assign({}, data);
        // 1 is subtracted because we also store the "total" key for summary results
        this.numSamples = Object.keys(this.jobStats.results.samples).length - 1;
      });
    }).catch(err => {
      $log.error('Failed to fetch job stats', err);
    });
  };

  // Object.defineProperty(this, 'searchMode', {
  //   get: () => resultState.searchMode,
  // });
  this.searchSuggest = val => {
    this.rawQuery = val;
  };

  this.suggestions = [];

  this.suggestionsAvailable = (inputWord, suggestions) => {
    if (!suggestions.length && this.suggestions.length) {
      this.suggestions = suggestions.map(replacement => {
        const index = this.searchQuery.lastIndexOf(replacement[1]);
        return [
          this.searchQuery.substr(0, index) +
          replacement[0] +
          this.searchQuery.substr(
            index + replacement[1].length,
            this.searchQuery.length
          ),
          replacement[1],
          replacement[2]
        ];
      });

      return;
    }

    if (!suggestions.length) {
      return;
    }

    const index = this.searchQuery.lastIndexOf(inputWord);

    if (index === -1) {
      return;
    }

    this.suggestions = suggestions.map(replacement => {
      return [
        this.searchQuery.substr(0, index) +
        replacement[0] +
        this.searchQuery.substr(
          index + inputWord.length,
          this.searchQuery.length
        ),
        inputWord,
        replacement[1]
      ];
    });
  };

  let submitTimeout = null;
  this.submitQueryChange = (value, force = false) => {
    if (value === this.searchQuery) {
      return;
    }

    if (submitTimeout) {
      $timeout.cancel(submitTimeout);
    }

    submitTimeout = $timeout(() => {
      if (value === "") {
        // Empty queries never trigger onQueryUpdate, because the search component
        // gets destroyed
        this.onQueryUpdate(value);
      }

      // Until we have better solution for autocomplete, we will not differentiate
      // between forced (e.g. user hits Enter) and non-forced updates, because we want
      // to issue queries on every character
      this.searchQuery = value;
    }, force ? 16 : 16);
  };

  this.onQuerySuggestionChosen = queryWithSuggestion => {
    this.viewQuery = queryWithSuggestion;

    this.onQueryUpdate(queryWithSuggestion);
  };

  function jobFullyComplete(job) {
    return (job.isCompleted() &&
      (job.isIndexCompleted() || job.isIndexFailed()) &&
      job.ancestry && job.ancestry.submission &&
      (job.ancestry.submission.state === 'completed' || job.ancestry.submission.state === 'failed'));
  }

  // TODO: move into separate component, duplicate of queue component
  this.checkingStatus = false;
  this.checkingStatusSuccess = false;
  this.checkingStatusError = false;

  this.checkIndexStatus = () => {
    this.checkingStatus = true;
    return this.jobBeingViewed
      .$checkIndexStatus()
      .then(
        () => {
          this.checkingStatusSuccess = true;

          // We need to reassign the jobBeingViewed to trigger the change detection
          this.jobBeingViewed = new Jobs(this.jobBeingViewed);
        },
        err => {
          this.checkingStatusError = err;
        }
      )
      .finally(() => {
        $timeout(() => {
          this.checkingStatus = false;
          this.checkingStatusSuccess = false;
          // Keep error visible
        }, 500);
      });
  };

  this.updateLocation = (key, val) => {
    const params = $location.search();
    params[key] = val;

    $location.search(params);
  };

  this.onMissingIndex = () => {
    this.jobBeingViewed.setIndexMissing();
    this.viewQuery = null;
    this.searchQuery = null;
  };

  this.fetchingResults = false;

  this.onErrorShown = () => {
    this.clearJobBeingViewed();
    this.updateError = null;
  };

  this.deregisterListener = null;

  this._updateJob = (updatedJob) => {
    const jobIsComplete = updatedJob.submission.state === "completed";
    const needsStats = (this.jobBeingViewed && this.jobBeingViewed._id !== updatedJob._id) || !this.jobStats;

    // Ensure AngularJS picks up the change
    // Because this can be called from job chooser
    this.jobBeingViewed = updatedJob;
    this._id = updatedJob._id;
    this.updateLocation("_id", updatedJob._id);

    const promises = [];
    if (jobIsComplete) {
      if (needsStats) {
        promises.push(this.getCompletedJobStats(updatedJob));
      }
    }

    return Promise.all(promises).finally(() => {
      $scope.$applyAsync(() => {
        this.jobResultsFetched = true;
      });
    });
  };


  this.updateJob = id => {
    if (!id) {
      $q.reject("id required in updateJob");
      return;
    }

    // We cannot pass a one-way bound job to the component from route resolver
    // and have reference changes propagate... annoying.
    // So we do this instead
    this.fetchingResults = true;
    this.updateError = null;

    //2nd argument (force == true) makes us get the full results
    //and makes jobTracker track this new data (basically including the results: property)
    jobTracker.initializeAsync().then(() =>
      jobTracker
        .getOneAsync(id, true)
        .then(updatedJob => {
          if (!updatedJob) {
            this.updateError = "Job not found";
            return;
          }

          this.jobBeingViewed = updatedJob;

          return this._updateJob(updatedJob).finally(() => {
            if (this.deregisterListener) {
              this.deregisterListener();
            }

            if (this.deregisterIndexCompleteListener) {
              this.deregisterIndexCompleteListener();
            }

            if (updateInterval) {
              $interval.cancel(updateInterval);
            }

            this.deregisterListener = $scope.$on(`${jobTracker.jobUpdatedEvent}:${updatedJob._id}`, (event, updatedJob2) => {
              this._updateJob(updatedJob2);
            });
            this.deregisterIndexCompleteListener = $scope.$on(`${jobEvents.eventPrefix}${jobEvents.events.searchIndex.completed}:${updatedJob._id}`, (event, updatedJob2) => {
              this._updateJob(updatedJob2);
            });

            updateInterval = $interval(() => {
              if (!this.jobBeingViewed) {
                $log.debug(`jobBeingViewed is null, cancelling update`);
                $interval.cancel(updateInterval);
                return;
              }
              if (jobFullyComplete(this.jobBeingViewed)) {
                $log.debug(`job ${this.jobBeingViewed._id} fully complete, skipping update`);
                return;
              }
              jobTracker.getOneAsync(updatedJob._id, true).then((updatedJob2) => {
                this._updateJob(updatedJob2).then(() => {
                  $log.debug("updated job", updatedJob2);
                });
              }).catch(err => {
                $log.error(err);
              });
            }, 15000);
          });
        })
        .catch(err => {
          this.updateError = err;

          $log.error(err);
        })
        .finally(() => {
          this.fetchingResults = false;
        })

    );
  };

  this.clearJobBeingViewed = () => {
    this._id = null;
    this.err = null;
    this.clearSearch();
    this.jobResultsFetched = false;

    this.removeClickListener();

    if (this.deregisterListener) {
      this.deregisterListener();
    }

    if (this.deregisterIndexCompleteListener) {
      this.deregisterIndexCompleteListener();
    }

    if (updateInterval) {
      $interval.cancel(updateInterval);
    }

    $location.search({
      _id: null
    });
  };

  this.$onInit = () => {
    this.setupClickListener();

    if ($routeParams.search) {
      if ($routeParams.size) {
        this.searchSize = $routeParams.size;
      }

      if ($routeParams.from) {
        this.searchFrom = $routeParams.from;
      }

      if ($routeParams.sort) {
        this.searchSort = $routeParams.sort;
      }

      if ($routeParams.q) {
        this.searchQuery = $routeParams.q;
      }

      this.searchMode = true;
    }
  };

  this.$onChanges = changesObj => {
    // Check for a real value, so that we can go back to the viewed job
    // when navigating between pages
    if (!changesObj._id.currentValue) {
      this.clearJobBeingViewed();
    }

    if (
      changesObj._id &&
      changesObj._id.currentValue &&
      changesObj._id.currentValue !== changesObj._id.previousValue
    ) {
      this.updateJob(changesObj._id.currentValue);
    }

    if (changesObj.type && changesObj.type.currentValue) {
      this.failBanner = `No ${changesObj.type.currentValue} jobs found`;
    }
  };

  this.$onDestroy = () => {
    this.clearJobBeingViewed();
  };

  this.toggleSearchMode = () => {
    this.searchMode = !this.searchMode;

    $location.search({
      _id: this._id,
      search: this.searchMode
    });
  };

  this.searchReady = false;
  this.onSearchReady = (ready, allSearchFields) => {
    $scope.$evalAsync((_, data) => {
      const [ready, allSearchFields] = data;

      if (!allSearchFields) {
        this.searchFields = [];
      } else {
        this.searchFields = allSearchFields.sort((a, b) => a.length - b.length);
      }

      this.searchReady = ready;
    }, [ready, allSearchFields]);
  };

  this.onQueryUpdate = (q, sort, size, from) => {
    if (q === "") {
      $location.search({
        _id: this._id
      });
      return;
    }

    // // We keep our query, because the search component modifications to the query
    // // string that we pass it is completely deterministic from the search query
    // // passed to it by the consumer; it is modified to elastic search syntax
    // // which will look confusing to the user
    $location.search({
      _id: this._id,
      search: true,
      q,
      sort,
      size,
      from
    });
  };

  this.onCannedQueryChosen = (query) => {
    this.viewQuery = query;

    this.submitQueryChange(query);
  };
}

angular
  .module("sq.jobs.results.component", [
    "sq.jobs.chooser.component",
    "sq.jobs.events.service",
    "sq.jobs.results.foamTree.component",
    "sq.jobs.upload.toS3.component",
    "sq.jobs.results.service",
    "sq.jobs.tracker.service",
    "sq.jobs.events.service",
    "sq.user.profile.service",
    "sq.jobs.infoCard.component",
    "sq.jobs.queue.status.component",
    "sq.jobs.model",
    "sq.jobs.results.search.suggest.component",
    "sq.jobs.results.search.hints.component",
    "sq.jobs.unauthorized.component",
    "sq.jobs.results.reindex.component",
    "sq.user.auth.tokens",
    'sq.jobs.model'
  ])
  .component("sqJobResults", {
    bindings: {
      // The submission object (could be search or main job submission)
      // Angular does not like "_id" in the url...so yeah
      _id: "<id",
      type: "<",
      // comes from jobTracker, we expect to have a resolved list of jobs here
      // jobs : '<',
    }, // isolate scope
    templateUrl: "jobs/results/jobs.results.tpl.html",
    controller: ResultsCtrl
  });
